import React from "react";
import cx from "classnames";
import styles from "./Avatar.module.scss";

interface Props {
  img?: string;
  children?: any;
  className?: string;
}

const Avatar = ({ img = "", className, children }: Props) => (
  <div className={cx(styles.fancyImgBorder, className)}>
    <div className={styles.img}>
      {children ? children : <img alt="character-token" src={img} />}
      <div className={styles.imgOverlay} />
    </div>
  </div>
);

export default Avatar;
