import React from "react";
import { EldritchRune } from "components/ui/icons/MagicCircles";
import styles from "components/views/Character.module.scss";
import FancyClassIcon from "components/ui/FancyClassIcon/FancyClassIcon"
import Avatar from "components/ui/avatar/Avatar"

interface Props {
  data: any;
}

interface ICharacterItem {
  name: string;
  type: string;
  data: {
    subclass: string;
  };
}

const CharacterTemplate = ({ data }: Props) => {
  const {
    name,
    // img,
    token: { img },
    items,
    data: {
      details: {
        biography,
        race,
        background,
        personality,
        ideals,
        bonds,
        flaws,
        alignment,
      },
    },
  } = data;
  const characterClassItem = items.filter(
    (i: ICharacterItem) => i.type === "class"
  )[0];
  const {
    name: characterClass,
    data: { subclass },
  } = characterClassItem;

  return (
    <>
      <div className={styles.root}>
        <div className={styles.foreground}>
          <FancyClassIcon characterClass={characterClass} className={styles.iconContainer} />

          <div className={styles.content}>
            <h1 className={styles.title}>{name}</h1>
            <h2 className={styles.classContainer}>
              {characterClass} {subclass && `(${subclass})`}
            </h2>

            <div className={styles.attribute}>
              <b>Race: </b>
              {race}
            </div>
            <div className={styles.attribute}>
              <b>Background: </b>
              {background}
            </div>
            <div className={styles.attribute}>
              <b>Alignment: </b>
              {alignment}
            </div>
            <Avatar img={img}/>

            <h2 className={styles.bioHeader}>Biography</h2>
            <div
              className={styles.bio}
              dangerouslySetInnerHTML={{ __html: biography.value }}
            />
            <div className={styles.traitsWrap}>
              <div className={styles.trait}>
                <h2>Personality:</h2>
                <div dangerouslySetInnerHTML={{ __html: personality.value }} />
              </div>
              <div className={styles.trait}>
                <h2>Ideals:</h2>
                <div dangerouslySetInnerHTML={{ __html: ideals.value }} />
              </div>
              <div className={styles.trait}>
                <h2>Bonds:</h2>
                <div dangerouslySetInnerHTML={{ __html: bonds.value }} />
              </div>
              <div className={styles.trait}>
                <h2>Flaws:</h2>
                <div dangerouslySetInnerHTML={{ __html: flaws.value }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.runeContainer}>
        <EldritchRune className={styles.eldritchRune} />
      </div>
    </>
  );
};

export default CharacterTemplate;
