import React from "react";
import CharacterTemplate from "components/views/CharacterTemplate";
import AppContentContainer from "components/layouts/appLayout/AppContentContainer";
import SEO from "components/seo";

import JSONData from "../../content/fvtt-Actor-Devana_Morgrave.json";

const DevanaPage = () => {
  return (
    <AppContentContainer spacing="none">
      <SEO title={JSONData.name} />
      <CharacterTemplate data={JSONData} />
    </AppContentContainer>
  );
};

export default DevanaPage;
